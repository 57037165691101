"use client";

import { gsap } from "gsap";
import { useState } from "react";
import { API_BASE } from "../../../constants/config";
import { TitleAnimation } from "../TitleAnimation/TitleAnimation";
import style from "./ExcellenceAwards.module.scss";
import Link from "next/link";

interface ExcelenceProps {
  messages: {
    first_word: string;
    second_word: string;
    awardsList: AwardItem[];
  };
  isFastTitleAnimation?: boolean;
}
interface AwardItem {
  id: number;
  year: string;
  title: string;
  medal_name: string;
  badge: string;
  logo: string;
  link: string;
}

export const ExcellenceAwards = ({
  messages,
  isFastTitleAnimation = false,
}: ExcelenceProps) => {
  const [logo, setLogo] = useState(null);
  const handleOnEnter = (e) => {
    const currentLogo = e.target.querySelector(`.${style.itemLogo}`);
    if (!currentLogo) return;
    setLogo((old) => {
      if (old != null) {
        gsap.to(old, {
          opacity: 0,
          ease: "sine.inOut",
          duration: 0.15,
        });
      }

      gsap.set(currentLogo, {
        left: e.pageX,
      });
      gsap.to(currentLogo, {
        opacity: 1,
        ease: "sine.inOut",
        duration: 0.15,
      });

      return currentLogo;
    });
  };

  const handleOnMove = (e) => {
    if (!logo) return;

    gsap.to(logo, {
      left: e.pageX,
      ease: "sine.inOut",
      duration: 0.15,
    });
  };

  const handleOnLeave = (e) => {
    if (!logo) return;
    gsap.to(logo, {
      opacity: 0,
      ease: "sine.inOut",
      duration: 0.15,
    });
  };
  if (!messages?.awardsList) return null;

  return (
    <section className={style.sectionContainer}>
      <div className="container">
        <TitleAnimation
          messages={{
            first_word: messages.first_word,
            second_word: messages.second_word,
          }}
          isSmall={isFastTitleAnimation}
        />
      </div>
      <div className={style.recognitionContainer}>
        {messages.awardsList &&
          messages.awardsList.map((item, i) => {
            return (
              <Link
                href={item.link || ""}
                target="_blank"
                onMouseEnter={(e) => handleOnEnter(e)}
                onMouseMove={(e) => handleOnMove(e)}
                onMouseLeave={(e) => handleOnLeave(e)}
                key={i}
                className={`${style.recognitionItem}`}
              >
                <div className={`${style.itemDetail} container itemDetail${i}`}>
                  <div className={`${style.text} text${i}`}>
                    <span>
                      {item.year} <small>{item.medal_name}</small>
                    </span>
                    <h3>{item.title}</h3>
                  </div>
                  <div className={`${style.svgContainer} svgContainer${i}`}>
                    <img
                      data-name="NeedColorSwitch"
                      src={`${API_BASE}${item.logo}`}
                      aria-label={item.title}
                      className={style.svgObject}
                      alt={item.title || "logo"}
                      loading="lazy"
                    />
                  </div>
                  {item?.badge ? (
                    <img
                      className={`${style.itemLogo}`}
                      src={`${API_BASE}${item.badge}`}
                      alt={item.title || "badge"}
                      loading="lazy"
                    />
                  ) : null}
                </div>
              </Link>
            );
          })}
      </div>
    </section>
  );
};
